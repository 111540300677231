import React from 'react';
import { graphql } from 'gatsby';
// import { Link } from 'gatsby';
import { connect } from 'react-redux';

import SiteComponent from '../../components/SiteComponent';
import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';

import Layout from '../../components/Layout';
import Strip from '../../components/Strip';

import styles from './style.module.css';
import Translate from '../../components/Translate';

class WorkWithUs extends SiteComponent {
	constructor(props) {
		super(props, true);
	}

	render() {
		const data = this.props.data;
		const fields = data.page.acf;

		/**
		 * Cover strip
		 **/

		const coverStrip = {
			title: fields.cover_box.title || "",
			content: fields.cover_box.content || "",
			image: 
			fields && 
			fields.cover_box && 
			fields.cover_box.image && 
			fields.cover_box.image.localFile && 
			fields.cover_box.image.localFile.childImageSharp  && 
			fields.cover_box.image.localFile.childImageSharp.original ?
			fields.cover_box.image.localFile.childImageSharp.original : "",
			image_thumb: 
			fields && 
			fields.cover_box && 
			fields.cover_box.image && 
			fields.cover_box.image.localFile && 
			fields.cover_box.image.localFile.childImageSharp && 
			fields.cover_box.image.localFile.childImageSharp.resolutions ?
			
			fields.cover_box.image.localFile.childImageSharp.resolutions : "",
		};

		let coverStripTag = (
			<Strip
				className={styles.coverStrip}
				classNameChildren={styles.coverStripChildren}
				backgroundImageSrcUrl={coverStrip.image.src}
				backgroundImageThumbSrcUrl={coverStrip.image_thumb.src}>
				<div className={styles.coverBox}>
					<h1 className={styles.coverTitle} dangerouslySetInnerHTML={{ __html: coverStrip.title }} />
					<p className={styles.coverDescription} dangerouslySetInnerHTML={{ __html: coverStrip.content }} />
				</div>	
			</Strip>
		);

		/**
		 * General info strip
		 **/

		const generalInfoStrip = {
			title: fields.general_info.title || "",
			content: fields.general_info.content || ""
		};

		let generalInfoStripTag = (
			<Strip
				className={styles.generalInfoStrip}
				classNameChildren={styles.generalInfoStripChildren}>
				<h1 className={styles.generalInfoStripTitle} dangerouslySetInnerHTML={{ __html: generalInfoStrip.title }} />
				<p className={styles.generalInfoStripDescription} dangerouslySetInnerHTML={{ __html: generalInfoStrip.content }} />
			</Strip>
		);

		/**
		 * Jobs strip
		 **/

		const jobsStrip = fields.jobs;

		let jobsStripTag = (
			<Strip
				className={styles.jobsStrip}
				classNameChildren={styles.jobsStripChildren}>

				<ul className={styles.jobsStripList}>
					{jobsStrip && jobsStrip.length > 0 ? jobsStrip.map((job, index) => {
						return (
							<li key={index} className={styles.jobsStripListItem}>
								<h2 className={styles.jobsStripListItemTitle} dangerouslySetInnerHTML={{ __html: job.title || "" }} />
								<p className={styles.jobsStripListItemDescription} dangerouslySetInnerHTML={{ __html: job.description || "" }} />
							</li>
						);
					}, this) : <span />}
				</ul>

				
			</Strip>
		);

		/**
		 * Training box strip
		 **/

		const trainingBoxStrip = {
			title: fields.training_box.title || "",
			content: fields.training_box.content || "",
			image: 
			fields &&
			fields.training_box &&
			fields.training_box.cover &&
			fields.training_box.cover.localFile &&
			fields.training_box.cover.localFile.childImageSharp &&
			fields.training_box.cover.localFile.childImageSharp.original ?
			fields.training_box.cover.localFile.childImageSharp.original : "",
			image_thumb: 
			fields &&
			fields.training_box &&
			fields.training_box.cover &&
			fields.training_box.cover.localFile &&
			fields.training_box.cover.localFile.childImageSharp &&
			fields.training_box.cover.localFile.childImageSharp.resolutions ?
			fields.training_box.cover.localFile.childImageSharp.resolutions  : ""
		};

		let trainingBoxStripTag = (
			<Strip
				className={styles.trainingBoxStrip}
				classNameChildren={styles.trainingBoxStripChildren}
				backgroundImageSrcUrl={trainingBoxStrip.image.src}
				backgroundImageThumbSrcUrl={trainingBoxStrip.image_thumb.src}>
				<div className={styles.trainingBox}>
					<h1 className={styles.trainingBoxStripTitle} dangerouslySetInnerHTML={{ __html: trainingBoxStrip.title }} />
					<p className={styles.trainingBoxStripDescription} dangerouslySetInnerHTML={{ __html: trainingBoxStrip.content }} />
				</div>
			</Strip>
		);

		/**
		 * Join us strip
		 **/

		const joinUsStrip = {
			title: fields.join_us_box.title || "",
			content: fields.join_us_box.content || "",
			email: fields.join_us_box.contact_email || ""
		};

		let joinUsStripTag = (
			<Strip
				className={styles.joinUsStrip}
				classNameChildren={styles.joinUsStripChildren}>
				<h1 className={styles.joinUsStripTitle} dangerouslySetInnerHTML={{ __html: joinUsStrip.title }} />
				<p className={styles.joinUsStripDescription} dangerouslySetInnerHTML={{ __html: joinUsStrip.content }} />

				<p>
					<Translate>workWithUs.writeTo</Translate>
					<a className={styles.joinUsStripEmail} href={`mailto:${joinUsStrip.email}`}>
						<span dangerouslySetInnerHTML={{__html: joinUsStrip.email}}/>
					</a>
				</p>
			</Strip>
		);

		return (
			<Layout metadata={this.props.data.page.yoast}>
				{coverStripTag}
				{generalInfoStripTag}
				{jobsStripTag}
				{trainingBoxStripTag}
				{joinUsStripTag}
			</Layout>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(WorkWithUs);

export const pageQuery = graphql`
	query($id: String!) {
		page: wordpressPage(id: { eq: $id }) {
			id
			slug
			title
			language: polylang_current_lang_code
			polylang_translations {
				id
				slug
				title
				polylang_current_lang_code
			}
			yoast {
				title
				metadesc
				metakeywords
				noindex_nofollow: meta_robots_nofollow
				canonical
				social_title: opengraph_title
				social_description: opengraph_description
				social_image: opengraph_image
			}
			acf {
				cover_box {
					title
					content
					image {
						localFile {
							childImageSharp {
								original {
									src
								}
								resolutions(width: 100, height: 100) {
									...GatsbyImageSharpResolutions_withWebp_tracedSVG
								}
								fluid {
									sizes
									srcSet
									aspectRatio
									base64
									src
								}
							}
						}
					}
				}
				general_info {
					title
					content
				}
				jobs {
					title
					description
				}
				training_box {
					title
					content
				}
				join_us_box {
					title
					content
					contact_email
				}
			}
		}
	}
`;
